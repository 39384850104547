<template>
    <div>
        <div class="content">
            <div id="a-header" class="header">
                <h1 v-if="article.article_type_id === 1" id="a-h1">{{ $t('article_app.news_article') }}</h1>
                <h1 v-else id="a-h1">{{ $t('article_app.tip_article') }}</h1>
                <div id="a-header-buttons" v-click-outside="closeDropdown" class="header-buttons">
                    <div class="language-select header">
                        <vue-multiselect
                            id="languages"
                            v-model="language"
                            :allow-empty="false"
                            :options="languages"
                            :searchable="false"
                            v-bind="translations">
                            <template slot="singleLabel" slot-scope="props">
                                <img :src="'/svg/flags/' + props.option.code + '.svg'" alt="country flag">
                                <span v-text="props.option.name"></span>
                            </template>
                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <img :src="'/svg/flags/' + props.option.code + '.svg'" alt="check">
                                    <span class="option__title">{{ props.option.name }}</span>
                                </div>
                            </template>
                        </vue-multiselect>
                    </div>
                    <div class="header-button-container">
                        <div class="button green">
                            <span
                                @click="publish">{{
                                    !article.published_at ? $t('article_app.publish') : $t('article_app.to_concept')
                                                 }}</span>
                            <div class="dropdown-button" @click="showPublishDropdown = !showPublishDropdown">
                                <img alt="collapse" src="/svg/collapse-arrow-white.svg">
                            </div>
                        </div>
                        <div v-if="showPublishDropdown === true" class="dropdown-list">
                            <div class="dropdown-item" @click="publish">
                                {{ !article.published_at ? $t('article_app.publish') : $t('article_app.to_concept') }}
                            </div>
                            <div class="dropdown-item" @click="previewArticle">{{ $t('article_app.preview') }}</div>
                            <div class="dropdown-item" @click="duplicateArticle">{{ $t('article_app.duplicate') }}</div>

                            <!--<div class="dropdown-item" v-if="this.language.name !== 'Nederlands'" @click="translateArticle('Nederlands')">{{ $t('article_app.translate_dutch') }}</div>-->
                            <!--<div class="dropdown-item" v-if="this.language.name !== 'English'" @click="translateArticle('English')">{{ $t('article_app.translate_english') }}</div>-->
                            <!--<div class="dropdown-item" v-if="this.language.name !== 'Deutsch'" @click="translateArticle('Deutsch')">{{ $t('article_app.translate_german') }}</div>-->
                            <!--<div class="dropdown-item" v-if="this.language.name !== 'Français'" @click="translateArticle('Français')">{{ $t('article_app.translate_french') }}</div>-->
                            <!--<div class="dropdown-item" v-if="this.language.name !== 'Polski'" @click="translateArticle('Polski')">{{ $t('article_app.translate_polish') }}</div>-->
                            <div class="dropdown-item" @click="showDeleteArticleModal = true">
                                {{ $t('article_app.delete_article') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="buttons" class="buttons"></div>
            </div>

            <article-tabs :article="article" />

            <router-view :article="article" :language="language"></router-view>

            <portal-target name="modals">
                <!--Teleport modals here-->
            </portal-target>

            <portal to="modals">
                <delete-article-modal v-if="showDeleteArticleModal" @close="showDeleteArticleModal = false"
                                      @confirm="deleteArticle" />
            </portal>
        </div>
    </div>
</template>

<script>
    import { mapMutations } from 'vuex';
    import draggable from 'vuedraggable';
    import ArticleTabs from './ArticleTabs';
    import ArticleTabContent from './ArticleTabContent';
    import VueMultiselect from 'vue-multiselect/src/Multiselect';
    import ClickOutside from 'vue-click-outside';
    import moment from 'moment';

    export default {
        name: 'article-app',

        components: {
            ArticleTabContent,
            ArticleTabs,
            draggable,
            VueMultiselect,
        },

        directives: {
            ClickOutside,
        },

        data() {
            return {
                showDeleteArticleModal: false,
                showPublishDropdown: false,
                translations: {
                    placeholder: this.$t('article_app.article_select_language'),
                    label: 'name',
                    'track-by': 'code',
                    selectLabel: '',
                    selectedLabel: '',
                    deselectLabel: '',
                },
                languages: [],
            };
        },

        computed: {
            article: {
                set(article) {
                    this.setArticle(article);
                },
                get() {
                    return this.$store.getters['articles/article'];
                },
            },
            language: {
                set(language) {
                    this.setLanguage(language);
                },
                get() {
                    return this.$store.getters['articles/language'];
                },
            },
        },

        methods: {
            ...mapMutations({
                setArticle: 'articles/set_article',
                setLanguage: 'articles/set_language',
            }),
            closeDropdown() {
                this.showPublishDropdown = false;
            },
            publish() {
                let stateArticle = this.$store.state.articles.article;

                if (stateArticle.publish_at) {
                    const publishDate = moment(stateArticle.publish_at);
                    const minimumDate = moment().add(15, 'minutes');

                    if (publishDate.isBefore(minimumDate)) {
                        this.$resetMessages();
                        this.$flashMessage(this.$t('article_app.publish_date_future'), 5000, 'error');
                        this.closeDropdown();
                        return;
                    }
                }

                if (stateArticle.featured_image !== null && stateArticle.title !== null) {
                    window.axios.post('/articles/' + this.article.id + '/publish')
                          .then((response) => {
                              const newPublishedAtState = !stateArticle.published_at ? moment().format('YYYY-MM-DD') : null;
                              this.$store.commit('articles/set_article_published_at_date', newPublishedAtState);
                              this.$resetMessages();
                              if (response.data.published_at !== null) {
                                  this.$flashMessage(this.$t('article_app.article_published_flash'), 5000, 'succes');
                              } else {
                                  this.$flashMessage(this.$t('article_app.article_concept_flash'), 5000, 'succes');
                              }
                          });
                } else {
                    this.$resetMessages();
                    this.$flashMessage(this.$t('article_app.article_title_image_missing'), 5000, 'error');
                }
                this.closeDropdown();
            },
            getArticle() {
                window.axios.get('/articles/get', {
                    params: {
                        id: this.$route.params.id,
                    },
                }).then((response) => {
                    this.setArticle(response.data);
                }).catch(err => {
                    this.$router.push({ name: 'news' });
                });
            },
            deleteArticle() {
                window.axios.post('/articles/' + this.article.id + '/delete',
                ).then(() => {
                    this.$router.push('/nieuws');
                });
            },

            previewArticle() {
                const baseAppUrl = process.env.MIX_AB_IONIC_APP_URL;
                const redirectUrl = `${baseAppUrl}/news/${this.article.id}?lang=${this.language.code}`;

                const previewUrl = `${baseAppUrl}?redirectUrl=${redirectUrl}`;
                window.open(previewUrl, '_blank');
            },

            duplicateArticle() {
                window.axios.get('/articles/' + this.article.id + '/duplicate',
                ).then(() => {
                    this.$flashMessage(this.$t('article_app.article_duplicated_flash'), 5000, 'succes');
                    this.$router.push('/nieuws');
                });
            },

            translateArticle(toLanguage) {
                const data = { fromLanguage: this.language.name, toLanguage: toLanguage };
                window.axios.post('/articles/' + this.article.id + '/translate', data)
                      .then(() => {
                          this.$flashMessage(this.$t('article_app.article_translated_flash'), 5000, 'succes');
                          this.$router.push('/nieuws');
                      });
            },

        },

        mounted() {
            document.addEventListener('scroll', (event) => {
                    let element = document.getElementById('a-header');
                    let h1 = document.getElementById('a-h1');
                    let buttons = document.getElementById('a-header-buttons');
                    if (element) {
                        if (element.getBoundingClientRect().y < 24) {
                            element.style.boxShadow = '0px 4px 12px rgba(0, 0, 0, 0.1)';
                            element.style.background = '#F9FAFB';
                            element.style.width = 'calc(100vw - 280px)';
                            element.style.padding = '19px 0';
                            element.style.transform = 'translateX(-80px)';
                            h1.style.marginLeft = '80px';
                            h1.style.marginTop = '10px';
                            h1.style.marginBottom = '10px';
                            buttons.style.marginRight = '95px';
                            buttons.style.padding = '6px 0';
                        } else {
                            element.style.boxShadow = null;
                            element.style.background = null;
                            element.style.width = null;
                            element.style.transform = null;
                            element.style.padding = null;
                            h1.style.marginLeft = null;
                            h1.style.marginTop = null;
                            h1.style.marginBottom = null;
                            buttons.style.marginRight = null;
                            buttons.style.padding = null;
                        }
                    }
                },
            );
        },

        beforeMount() {
            window.axios.get('/languages').then(response => {
                this.languages = response.data;

                if (window.authUser.language_relation) {
                    this.language = window.authUser.language_relation;
                } else {
                    this.language = this.languages.find(language => {
                        return language.default === 1;
                    });
                }

                this.getArticle();
            });
        },
    };
</script>
