export default {
    general: {
        yes: 'yes',
        no: 'no',
        settings: 'settings',
        division: 'Division',
        status: 'Status',
        search: 'Search',
    },
    navigation: {
        instructions: 'Instructions',
        news: 'News',
        documents: 'Documents',
        users: 'Users',
        contacts: 'Contacts',
        events: 'Events',
        statistics: 'Statistics',
        push_notification: 'Push notification',
        ideabox: 'Ideabox',
        business_units: 'Divisions',
        locations_on_route: 'Locations on route',
        furloughs: 'Furlough',
        roles: 'Roles',
        log_out: 'Log-out',
        my_service_schedule: 'My service schedule',
        departments: 'Departments',
        language_switch: {
            title: 'Language',
            languages: {
                nl: 'Dutch',
                en: 'English',
                de: 'German',
            },
        },
    },
    languages: {
        nl: 'Dutch',
        en: 'English',
        de: 'German',
        fr: 'French',
        pl: 'Polish',
    },
    locations_on_route: {
        data: 'data',
        locations_on_route: 'Locations on route',
        select_language: 'Select language',
        add_location: 'Add location',
        title: 'Title',
        division: 'Division',
        divisions: 'Divisions',
        change_location: 'Change Location',
        save_changes: 'Save changes',
        add_new_location: 'Add new location',
        select_division: 'Select division',
        no_results: 'No resuilts',
        button_text: 'Button text',
        button_url: 'Button url',
        description: 'Description',
        location_saved: 'Location saved',
        delete: 'Delete',
        location_delete_failed: 'Failed to delete, please try again later',
    },
    ab_component: {
        component_delete: 'Delete component',
        title_big: 'Title big',
        title_small: 'Title small',
        textbox: 'Textbox',
        add_pdf: 'Add an PDF',
        preview_pdf: 'Preview PDF',
        delete_pdf: 'Delete PDF',
        pdf_name: 'PDF name',
        or: 'or',
        poll_question: 'Question',
        poll_answer: 'Option',
        poll_add_answer: 'Add option',
    },
    upload_component: {
        add_files: 'Drop files in here',
        or: 'or',
        add_image: 'Add an image',
        add_pdf: 'Add a PDF',
        delete_image: 'Delete image',
        image_to_large: 'Upload images smaller than 22mb',
    },
    article_app: {
        news_article: 'News article',
        tip_article: 'Tip article',
        preview: 'Preview',
        delete_article: 'Delete article',
        publish: 'Publish',
        save: 'Save',
        to_concept: 'Convert to draft',
        article_published_flash: 'Article published',
        article_duplicated_flash: 'Article is being duplicated, this may take a minute.',
        article_translated_flash: 'Article is being translated, this may take a minute.',
        article_concept_flash: 'Article converted to draft',
        article_title_image_missing: 'Title or main image has not yet been entered',
        article_select_language: 'Select language',
        duplicate: 'Duplicate',
        translate_dutch: 'Translate to Dutch',
        translate_english: 'Translate to English',
        translate_german: 'Translate to German',
        translate_french: 'Translate to French',
        translate_polish: 'Translate to Polish',
        publish_date_future: 'The publication date must be at least 15 minutes in the future.',
    },
    article_related_articles: {
        related_articles: 'Related articles',
        article_one: 'Article 1',
        article_two: 'Article 2',
        article_three: 'Article 3',
        no_results: 'No results',
        select_article: 'Select article',
        all_divisions: 'All divisions',
    },
    article_tab_content: {
        general_info: 'General data',
        title: 'Title',
        primary_image: 'Primary image',
        content: 'Content',
        publish_at: 'Publish at',
        select_date: 'Pick a date',
        article_success_flash: 'Saved successfully',
        image_to_large: 'Upload images smaller than 22mb',
    },
    article_tab_tags: {
        title: 'Add tags',
        select: 'Select one or more tags',
    },
    article_tab_division: {
        divisions: 'Divisions',
        no_results: 'No results',
        function: 'Function',
        all_functions: 'All functions',
        all_divisions: 'All divisions',
        placeholder: 'Select division(s)',
    },
    article_tab_profile: {
        linked_profile: 'Linked profile',
        title: 'Title',
        description: 'Description',
        profile: 'Profile',
        select_profile: 'Select profile',
        all_divisions: 'All divisions',
        all_departments: 'All departments',
        all_functions: 'All functions',
        placeholder: 'Select division(s)',
    },
    article_tabs: {
        content: 'Content',
        story: 'Story of',
        linked_profile: 'Linked profile',
        related_articles: 'Related articles',
        divisions: 'Divisions',
        push_notification: 'Push notifications',
    },
    article_push_notification_tabs: {
        send: 'Send',
        overview: 'Overview',
        information: 'Information',
        push_notification_language: 'Push notification English',
        no_push_notifications_yet: 'No push notifications have been send about this article.',
        date: 'Date',
    },
    article_tab_story: {
        profile: 'Profile',
        select_profile: 'Select profile',
        story_of: 'Story of',
        all_divisions: 'All divisions',
        all_departments: 'All departments',
    },
    component_list: {
        add_component: 'Add component',
        title_big: 'Title big',
        title: 'Title',
        textbox: 'Textbox',
        image: 'Image',
        pdf: 'PDF',
        poll: 'Poll',
        saved_success_flash: 'Saved successfully',
    },
    delete_article_modal: {
        delete_article: 'Do you want to delete this article?',
        warning: 'This cannot be undone.',
        cancel: 'Cancel',
        delete: 'Delete',
    },
    delete_chapter_modal: {
        delete_chapter: 'Delete chapter',
        warning: 'Do you really want to delete this chapter? This cannot be undone',
        cancel: 'Cancel',
        delete: 'Delete',
    },
    delete_component_modal: {
        delete_component: 'Do you want to remove this component?',
        warning: 'This cannot be undone.',
        cancel: 'Cancel',
        delete: 'Delete',
    },
    delete_doc_modal: {
        delete_doc: 'Do you want to delete this document?',
        warning: 'This cannot be undone.',
        cancel: 'Cancel',
        delete: 'Delete',
    },
    delete_map_modal: {
        delete_map: 'Do you want to delete this document?',
        warning: 'This cannot be undone.',
        cancel: 'Cancel',
        delete: 'Delete',
    },
    delete_paragraph_modal: {
        delete_map: 'Do you want to delete this paragraph?',
        warning: 'This cannot be undone.',
        cancel: 'Cancel',
        delete: 'Delete',
    },
    delete_sub_paragraph_modal: {
        delete_sub_paragraph: 'Do you want to delete this subsection?',
        warning: 'This cannot be undone.',
        cancel: 'Cancel',
        delete: 'Delete',
    },
    no_language_modal: {
        notification: 'Cannot save the changes',
        argument: 'No language is selected.',
        ok: 'OK',
    },
    documents: {
        date_agreed: 'Date agreed',
        agreed: 'Agreed',
        disagreed: 'Disagreed',
        show_all: 'Show all',
    },
    documents_tabs: {
        content: 'Content',
        agree: 'To agree',
    },
    documents_add_doc: {
        add_new_document: 'Add new document',
        add_document: 'Add document',
        data: 'Data',
        title: 'Title',
        file: 'File',
        drag_file: 'Drag a file here',
        or: 'or',
        add_file: 'Attach a file',
        delete_file: 'Delete file',
        replace_file: 'Replace file',
        has_to_accept: 'Has to accept',
    },
    documents_add_map: {
        add_new_map: 'Add new folder',
        add_map: 'Add folder',
        data: 'Data',
        title: 'Title',
        divisions: 'Divisions',
        no_results: 'No results',
        function: 'Function',
        language: 'Language',
        select_language: 'Select language',
        select_function: 'Select function',
        all_divisions: 'All divisions',
        select_map: 'Select a folder',
    },
    documents_add_link: {
        add_link: 'Add link',
        data: 'Data',
        link: 'Link',
        title: 'Title',
        example_url: 'e.g. https://www.abtexelgroup.com/fleet',
    },
    documents_app: {
        add_document: 'Add document',
        add_folder: 'Add folder',
        add_link: 'Add link',
        title: 'Title',
        last_modified: 'Last modified',
        search_placeholder: 'Search...',
        no_searchresults: 'No search results',
    },
    documents_edit_doc: {
        edit_doc: 'Edit document',
        delete_doc: 'Delete document',
        save_changes: 'Save changes',
        data: 'Data',
        title: 'Title',
        file: 'File',
        drag_file: 'Drag a file here',
        or: 'or',
        add_file: 'Attach a file',
        delete_file: 'Delete file',
        replace_file: 'Replace file',
        preview_pdf: 'Preview PDF',
        image_to_large: 'Upload images smaller than 22mb',
    },
    documents_edit_link: {
        edit_link: 'Edit link',
        delete_link: 'Delete link',
        save_changes: 'Save changes',
        data: 'Data',
        title: 'Title',
    },
    documents_edit_map: {
        edit_folder: 'Edit folder',
        delete_folder: 'Delete folder',
        save_changes: 'Save changes',
        data: 'Data',
        title: 'Title',
        divisions: 'Divisions',
        no_results: 'No results',
        select_language: 'Select language',
        language: 'Language',
        all_functions: 'All functions',
        all_divisions: 'All divisions',
        function: 'Function',
    },
    manual_app: {
        deadline: 'Please choose a deadline',
    },
    manual_chapter: {
        delete_chapter: 'Delete chapter',
        preview: 'Preview',
        add_paragraph: 'Add paragraph',
    },
    manual_component: {
        saved_success: 'Saved successfully',
    },
    manual_header: {
        instructions: 'Instructions',
        basic_manual: 'Basic manual',
        business_unit_specific: 'Business unit specific',
        function_specific: 'Function specific',
        publish: 'Publish',
        select_language: 'Select language',
        version: 'Version',
    },
    manual_google_translate: {
        started: 'Translation has started',
        translate_to_english: 'Translate to English',
        automatic_translation: 'Automatic translation',
        automatic_translation_warning: 'Are you sure you want to translate this instruction into English? The current content for this language will be overwritten. Generating the translation may take several minutes.',
        translate: 'Translate',
    },
    manual_paragraph: {
        delete_paragraph: 'Delete section',
        add_subsection: 'Add subsection',
    },
    manual_subsection: {
        delete_subsection: 'Delete subsection',
    },
    manual_tab_adjustments: {
        delete_subsection: 'Delete subsection',
        adjustments: 'Adjustments',
        deadline: 'Agree deadline',
        pick_date: 'Choose a date',
        contactperson: 'Contactperson',
    },
    manual_tab_confirmed: {
        confirmed: 'Agree',
        not_confirmed: 'Not agreed',
        show_all: 'Show all',
        title: 'Title',
        type: 'Type',
        date_agree: 'Date agreed',
        phone: 'Phone number',
        no_results: 'No results',
        business_unit: 'division',
    },
    manual_tab_content: {
        add_chapter: 'Add chapter',
        search: 'Search...',
        chapters: 'Chapters',
    },
    manual_tab_divisions: {
        divisions: 'Divisions',
        no_results: 'No results',
        function: 'Function',
        all_divisions: 'All divisions',
    },
    manual_tabs: {
        content: 'Content',
        adjustments: 'Adjustments',
        agree: 'To agree',
        divisions: 'Divisions',
    },
    new_chapter_modal: {
        add_chapter: 'Add chapter',
        chapter_name: 'Chapter name',
    },
    new_paragraph_modal: {
        add_paragraph: 'Add paragraph',
        name_paragraph: 'Paragraph name',
    },
    news_app: {
        news: 'News',
        tip_article: 'Add tip article',
        add_article: 'Add news article',
        search: 'Search...',
        title: 'Title',
        type: 'Type',
        last_edited: 'Last edited',
        planned_publication: 'Planned publication',
        posted: 'Posted',
        no_results: 'No search results',
    },
    new_subsection_modal: {
        add_subsection: 'Add subsection',
        name_subsection: 'Subsection name',
    },
    statistics_app: {
        statistics: 'Statistics',
        login: 'Login',
        news: 'News',
        fuelReports: 'Fuel reports',
    },
    statistics_app_fuel_reports: {
        reports_opened: 'Fuel reports opened',
        opened_number: 'Amount of times opened per month',
        opened_number_second: 'Amount of times opened',
        month: 'Month',
    },
    statistics_app_logins: {
        login_number: 'Number logged in weekly',
        week: 'Week',
        login_number_second: 'Number of times logged in',
        unique_login_number: 'Number of uniquely logged in weekly',
    },
    statistics_app_news: {
        news_opened: 'Opened news articles',
        news_article: 'News article',
        opened: 'Number of times opened',
        select_language: 'Select language',
        published_at: 'Publication date',
    },
    switch_confirmation_modal: {
        save_changes: 'Save changes',
        save_confirmation: 'Do you want to save these changes?',
        yes: 'Yes',
        no: 'No',
    },
    article_tab_push_notifications: {
        push_notification: 'Push notification',
        push_notification_dutch: 'Push notificatie Dutch',
        push_notification_polish: 'Push notificatie Polish',
        push_notification_english: 'Push notificatie English',
        push_notification_german: 'Push notificatie German',
        push_notification_french: 'Push notificatie French',
        send_notification: 'Send notification',
        save_notification: 'Save',
        message: 'You only send a push notification when the field is filled in. Example: If only the field for Dutch is filled in, only Dutch users will receive a notification',
        flash_message: 'Push notification send',
        flash_message_save: 'Push notification saved',
    },
    add_chapter_link_modal: {
        select_modal: 'Select manual',
        label: 'Label',
        manual: 'Manual',
        chapter: 'Chapter',
        paragraph: 'Paragraph',
        cancel: 'Cancel',
        insert: 'Insert',
    },
    manual_component_list: {
        add_component: 'Add component',
        title_big: 'Big title',
        title: 'Title',
        textbox: 'Textbox',
        image: 'Image',
    },
    delete_contact_person_confirmation_modal: {
        h1: 'Remove contact person?',
        paragraph: 'This cannot be undone.',
        no: 'No',
        yes: 'Yes',
    },
    contact_form: {
        has_whatsapp: 'Whatsapp',
        delete_contact: 'Delete contact',
        change_contact: 'Edit contact',
        add_contact: 'Add new contact',
        data: 'Personal information',
        divisions: 'Divisions',
        no_results: 'No results',
        department: 'Department',
        title: 'Title',
        subtitle: 'Subtitle',
        phone: 'Landline phone number',
        mobile_phone: 'Mobile phone',
        email: 'E-mail',
        language: 'Language',
        picture: 'Picture',
        contactpersons: 'Contactpersons',
        add_contactperson: 'Add extra contactperson',
        save_changes: 'Save changes',
        select_language: 'Select language',
        contactperson_saved: 'Contactperson saved',
        contactperson_deleted: 'Contactperson deleted',
        language_required: 'Language is required',
        invalid_phone: 'Invalid landline phone number',
        invalid_mobile_phone: 'Invalid mobile phone number',
    },
    contact_person: {
        name: 'Name',
        picture: 'Picture',
        contactperson_deleted: 'Contactperson deleted',
        save_changes: 'Save changes',
        add_contact: 'Add contact',
    },
    contact_index: {
        add_contact: 'Add contact',
        title: 'Title',
        subtitle: 'Subtitle',
        phone: 'Phone number',
        email: 'E-mail',
        departments: 'Departments',
        divisions: 'Divisions',
        no_results: 'No results',
        placeholder_departments: 'Select department',
        placeholder_divisions: 'Select division',
    },
    event_form: {
        change_event: 'Change event',
        add_new_event: 'Add new event',
        add_event: 'Add event',
        save_changes: 'Save changes',
        data: 'Data',
        title: 'Title',
        date_of: 'Date of',
        time_of: 'Time of',
        date_till: 'Date till',
        time_till: 'Time till',
        date_registration_deadline: 'Date registration deadline',
        time_registration_deadline: 'Time registration deadline',
        max_participants: 'Max participants (including any guests) (optional)',
        location: 'Location',
        address: 'Address',
        google_maps_url: 'Google maps URL',
        picture: 'Picture',
        description: 'Description',
        divisions: 'Divisions',
        no_results: 'No results',
        function: 'Function',
        text_push_notification: 'Text push notification',
        send_notification: 'Send notification to users in the selected divisions and functions',
        is_featured: 'Is featured',
        with_partner: 'With partner',
        present: 'Present',
        to_go: '{amount} are going',
        event_saved: 'Event saved',
        event_notification_send: 'Event notification sent',
        select_language: 'Select language',
        pick_date: 'Pick a date',
    },
    events: {
        events: 'Events',
        add_event: 'Add event',
        title: ' Title',
        date: 'Date',
        location: 'Location',
    },
    publish_manual_version_confirmation_modal: {
        publish: 'Publish manual?',
        no: 'No',
        yes: 'Yes',
    },
    push_notifications: {
        notifications: 'Notifications',
        pay_slip_notification: 'Pay slip notification',
        wage_supplement_notification: 'Wage supplement notification',
        data: 'Data',
        push_notification_dutch: 'Push notifications Dutch',
        push_notification_polish: 'Push notifications Polish',
        push_notification_english: 'Push notifications English',
        push_notification_german: 'Push notifications German',
        only_afas_users: 'Send only push notification to employees with a AB Texel contract.',
        divisions: 'Divisions',
        no_results: 'No results',
        send_notification: 'Send notification',
        all_divisions: 'All divisions',
        choose: 'Choose divisions',
        push_notification_send: 'Push notification sent',
        overview: 'Overview',
        search: 'Search',
        push_notificatie_dutch: 'Push notifications Dutch',
        date: 'Date',
        afas_users: 'Only AFAS users',
    },
    personal_document: {
        expiration_date: 'Expiration date',
        delete: 'delete',
        pick_a_date: 'Pick a date',
        front: 'Front',
        back: 'Back',
        document_upload: 'Upload document',
        certificates: 'Certificates',
        name_certificate: 'Certificate name',
        add_certificate: 'Add certificate',
        delete_document_type: 'Delete document type',
        add_type: 'Add document type',
    },
    users: {
        users: 'Users',
        add_user: 'Add user',
        search: 'Search...',
        name: 'Name',
        division: 'Division',
        function: 'Function',
        phone: 'Phone number',
        no_results: 'No results',
        not_available: 'Not available',
        accepted_terms: 'Accepted terms',
        last_login: 'Last login',
    },
    user_docs: {
        edit_user: 'Change user',
        add_user: 'Add user',
        general_data: 'General data',
        documents: 'Documents',
        document_types: 'Document types',
    },
    user_form: {
        general_data: 'General data',
        documents: 'Documents',
        edit_user: 'Edit user',
        add_new_user: 'Add new user',
        add_user: 'Add user',
        save_changes: 'Save changes',
        data: 'Data',
        afas_number: 'AFAS number',
        old_afas_number: 'Old AFAS number',
        name: 'Name',
        email: 'E-mail',
        divisions: 'Divisions',
        no_results: 'No results',
        function: 'Function',
        phone: 'Phone number',
        street: 'Street',
        number: 'Number',
        city: 'City',
        zip_code: 'Postal Code',
        country: 'Country',
        employment_start_date: 'Employment start date',
        employment_end_date: 'Employment end date',
        active_user: 'Active user',
        is_administrator: 'Is administrator?',
        view_news_items: 'View news items, documents, manuals and contact persons',
        news_from_division: 'News, documents, manuals and contacts from division(s)',
        available_payslips: 'Available payslips',
        no_payslips: 'No payslips available',
        available_wage_supplements: 'Available wage supplements',
        no_wage_supplements: 'No wage supplements available',
        user_saved: 'User saved',
        roles: 'Roles',
        visible_anniversary: 'Anniversaries and in and out of service visible',
        employee_number: 'Payroll number',
        invalid_phone: 'Invalid phone number',
    },
    roles: {
        roles: 'Roles',
        add_role: 'Add role',
        search: 'Search...',
        name: 'Name',
        divisions: 'Divisions',
        permissions: 'Permissions',
        no_results: 'No results',
    },
    role_form: {
        data: 'Data',
        name: 'Name',
        divisions: 'Divisions',
        permissions_label: 'Permissions',
        add_role: 'Add role',
        change_role: 'Edit role',
        delete_role: 'Delete',
        role_saved: 'Role saved',
        role_deleted: 'Role deleted',
        save_changes: 'Save changes',
        forbidden_message: 'Role can not be edited or deleted',
        permissions: {
            'add-and-edit-instructions': 'Add and edit instructions',
            'add-and-edit-news-items': 'Add and edit news items',
            'add-and-edit-documents': 'Add and edit documents',
            'add-and-edit-users': 'Add and edit users',
            'add-and-edit-contacts': 'Add and edit contacts',
            'add-and-edit-events': 'Add and edit events',
            'manage-push-notifications': 'Manage push notifications',
            'assess-expenses': 'Assess expenses',
            'see-statistics': 'See statistics',
            'add-and-edit-roles': 'Add and edit roles',
            'edit-divisions': 'Edit divisions',
            'see-ideabox': 'See ideabox',
            'manage-furloughs': 'Rate verlofaanvragen',
            'edit-my-service-roster': 'See and edit myService schedule',
            'add-and-edit-locations': 'Add and edit locations',
            'add-and-edit-departments': 'Add and edit departments',
            'view-poll-results': 'View poll results in AB-Connect',
            'change-division-or-function': 'Change division or function in AB-Connect',
        },
    },
    ideabox: {
        header: 'Ideabox',
        search: 'Search...',
        status: {
            open: 'open',
            doing: 'doing',
            done: 'handled',
        },
    },
    furloughs: {
        title: 'Furlough',
        applicant: 'Applicant',
        from: 'From',
        to: 'Till',
        status: 'Status',
        fleethours: 'Fleethours',
        statusus: {
            1: 'pending',
            2: 'approved',
            3: 'denied',
        },
        detail: {
            title: 'Furlough detail',
            data: 'Furlough data',
            explanation: 'Explanation',
            is_processed_fleethours: 'Is processed in FleetHours',
            saved: 'Saved',
        },
    },

    business_units: {
        business_unit: 'Division',
        business_units: 'Divisions',
        business_unit_management: 'Division management',
        saved: 'Changed successfully',
        permissions_label: 'App permissions',
        general_label: 'General information',
        emails_label: 'E-mail addresses',
        anniversary_item: 'Anniversary item',
        anniversaries_label: 'Anniversaries',
        add_anniversary: 'Add extra anniversary item',
        export_label: 'Export',
        exports: {
            type: 'Type',
            export: 'Export',
            types: {
                accepted_terms_last_login: 'Accepted terms / last login at',
            },
        },
        general: {
            name: 'Name',
        },
        emails: {
            expenses_email: 'Emailadres(ses) for expenses.',
            furlough_planning_email: 'Emailadres(ses) for planning (Furlough).',
            furlough_hours_control_email: 'Emailadres(ses) voor for checks (Furlough).',
            comma_seperated: 'Comma separated for multiple addresses. Example: (info@example.com, mail@example.com)',
        },
        permissions: {
            'tanklocaties-wasstraten': 'Locations on route',
            furlough: 'Furlough',
            expenses: 'Expenses',
            payslips: 'Payslips',
            work_clothing: 'Workklothing',
        },
    },
    redactor_custom_buttons: {
        article_link: 'Add article link',
        manual_link: 'Add manual link',
    },
    content_page: {
        changes_couldnt_be_saved: 'Changes could not be saved',
        changes_saved_successfully: 'Changes saved successfully',
        content_couldnt_be_loaded: 'Content could not be loaded',
    },
    departments: {
        departments: 'Departments',
        add_department: 'Add department',
        save_changes: 'Edit department',
        title: ' Title',
        data: 'Data',
        name_nl: 'Name Dutch',
        name_en: 'Name English',
        name_pl: 'Name Polish',
        name_de: 'Name German',
        name_fr: 'Name French',
        delete: 'Delete department',
        none_found: 'No departments found',
    },
    phone_number: {
        invalid_phone: 'The phone number is not valid according to the validation.',
    },
};

/* eslint-disable no-undef */
(function($R) {
    $R.lang.nl = {
        format: 'Format',
        image: 'Afbeelding',
        file: 'Bestand',
        link: 'Link',
        bold: 'Vet',
        italic: 'Cursief',
        deleted: 'Doorstreept',
        underline: 'Onderstreept',
        superscript: 'Superscript',
        subscript: 'Subscript',
        'bold-abbr': 'B',
        'italic-abbr': 'I',
        'deleted-abbr': 'S',
        'underline-abbr': 'U',
        'superscript-abbr': 'Sup',
        'subscript-abbr': 'Sub',
        lists: 'Lijsten',
        'link-insert': 'Link invoegen',
        'link-edit': 'Link Bewerken',
        'link-in-new-tab': 'Open link in nieuw tabblad',
        unlink: 'Ontkoppelen',
        cancel: 'Annuleren',
        close: 'Afsluiten',
        insert: 'Invoegen',
        save: 'Opslaan',
        delete: 'Verwijder',
        text: 'Tekst',
        edit: 'Bewerken',
        title: 'Titel',
        paragraph: 'Normale tekst',
        quote: 'Citaat',
        code: 'Code',
        heading1: 'Koptekst 1',
        heading2: 'Koptekst 2',
        heading3: 'Koptekst 3',
        heading4: 'Koptekst 4',
        heading5: 'Koptekst 5',
        heading6: 'Koptekst 6',
        filename: 'Bestandsnaam',
        optional: 'Optioneel',
        unorderedlist: 'Ongeordende lijst',
        orderedlist: 'Geordende lijst',
        outdent: 'Uitspringen',
        indent: 'Inspringen',
        horizontalrule: 'Streep',
        upload: 'Upload',
        'upload-label': 'Drop files here or click to upload',
        'upload-change-label': 'Drop a new image to change',
        'accessibility-help-label': 'RTF editor',
        caption: 'Caption',
        bulletslist: 'Ongeordende lijst',
        numberslist: 'Genummerde lijst',
        'image-position': 'Position',
        none: 'None',
        left: 'Left',
        right: 'Right',
        center: 'Center',
        undo: 'Undo',
        redo: 'Redo',
        table: 'Tabel',
        linkreference: 'Link hoofdstuk',
    };
}(Redactor));
/* eslint-enable no-undef */
