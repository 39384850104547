<template>
    <div v-if="isLoaded">
        <div class="content-head">
            <h1>{{ $t('article_related_articles.related_articles') }}</h1>
            <div class="input-group related-articles">
                <label for="artikel1">{{ $t('article_related_articles.article_one') }}</label>
                <vue-multiselect
                    :options="articleList"
                    label="title"
                    @close="save"
                    id="artikel1"
                    v-bind="translations"
                    v-model="article1"
                    :disabled="isDisabled(article1)"
                >
                    <template slot="singleLabel" slot-scope="props">
                        <span class="article__title">{{ props.option.title }}</span>
                        <span class="article__divisions">- {{ getArticleDivisionNamesString(props.option.business_units) }}</span>
                    </template>
                    <template slot="option" slot-scope="props">
                        <div class="option__desc">
                            <div class="option__data">
                                <span class="option__title">{{ props.option.title }}</span>
                                <span class="option__divisions">- {{ getArticleDivisionNamesString(props.option.business_units) }}</span>
                            </div>
                            <img alt="check" class="selected" src="/svg/check.svg">
                        </div>
                    </template>
                    <span slot="noResult">{{ $t('article_related_articles.no_results') }}</span>
                </vue-multiselect>
            </div>
            <div class="input-group related-articles">
                <label for="artikel2">{{ $t('article_related_articles.article_two') }}</label>
                <vue-multiselect
                    :options="articleList"
                    label="title"
                    @close="save"
                    id="artikel2"
                    v-bind="translations"
                    v-model="article2"
                    :disabled="isDisabled(article2)"
                >
                    <template slot="singleLabel" slot-scope="props">
                        <span class="article__title">{{ props.option.title }}</span>
                        <span class="article__divisions">- {{ getArticleDivisionNamesString(props.option.business_units) }}</span>
                    </template>
                    <template slot="option" slot-scope="props">
                        <div class="option__desc">
                            <div class="option__data">
                                <span class="option__title">{{ props.option.title }}</span>
                                <span class="option__divisions">- {{ getArticleDivisionNamesString(props.option.business_units) }}</span>
                            </div>
                            <img alt="check" class="selected" src="/svg/check.svg">
                        </div>
                    </template>
                    <span slot="noResult">{{ $t('article_related_articles.no_results') }}</span>
                </vue-multiselect>
            </div>
            <div class="input-group related-articles">
                <label for="artikel3">{{ $t('article_related_articles.article_three') }}</label>
                <vue-multiselect
                    :options="articleList"
                    label="title"
                    @close="save"
                    id="artikel3"
                    v-bind="translations"
                    v-model="article3"
                    :disabled="isDisabled(article3)"
                >
                    <template slot="singleLabel" slot-scope="props">
                        <span class="article__title">{{ props.option.title }}</span>
                        <span class="article__divisions">- {{ getArticleDivisionNamesString(props.option.business_units) }}</span>
                    </template>
                    <template slot="option" slot-scope="props">
                        <div class="option__desc">
                            <div class="option__data">
                                <span class="option__title">{{ props.option.title }}</span>
                                <span class="option__divisions">- {{ getArticleDivisionNamesString(props.option.business_units) }}</span>
                            </div>
                            <img alt="check" class="selected" src="/svg/check.svg">
                        </div>
                    </template>
                    <span slot="noResult">{{ $t('article_related_articles.no_results') }}</span>
                </vue-multiselect>
            </div>
        </div>
    </div>
</template>

<script>
    import ComponentList from "./ComponentList";
    import VueMultiselect from "vue-multiselect/src/Multiselect";

    export default {
        name: 'article-related-articles',
        components: { VueMultiselect, ComponentList },
        data() {
            return {
                isLoaded: false,
                article1: null,
                article2: null,
                article3: null,
                articles: [],
                translations: {
                    placeholder: this.$t('article_related_articles.select_article'),
                    label: "title",
                    'track-by': "id",
                    selectLabel: "",
                    selectedLabel: "",
                    deselectLabel: ""
                },
            }
        },
        props: {
            article: {
                required: true,
            },
            language: {
                required: true,
            },
        },
        methods: {
            getArticleDivisionNamesString(names) {
                if (names && names.length > 0) {
                    return names.join(', ')
                } else {
                    return this.$t('article_related_articles.all_divisions');
                }
            },
            getArticles() {
                window.axios.get(`/articles/list?permission=${this.$permissions.ADD_AND_EDIT_NEWS_ITEMS}`, {
                    params: {
                        light: true,
                        language: this.language.code
                    }
                }).then((response) => {
                    this.articles = response.data.filter(article => {
                        return article !== null;
                    });

                    this.setArticles();

                    this.isLoaded = true;
                });
            },
            setArticles() {
                if (this.article.articles) {
                    if (this.article.articles[0]) {
                        this.article1 = this.articleList.find(article => article.id === this.article.articles[0].id);
                    }
                    if (this.article.articles[1]) {
                        this.article2 = this.articleList.find(article => article.id === this.article.articles[1].id);
                    }
                    if (this.article.articles[2]) {
                        this.article3 = this.articleList.find(article => article.id === this.article.articles[2].id);
                    }
                }
            },

            save() {
                [this.article1, this.article2, this.article3] = [this.article1, this.article2, this.article3].sort();

                this.$store.dispatch('articles/set_related_articles', {
                    articles: [
                        this.article1,
                        this.article2,
                        this.article3
                    ]
                });
            },

            isDisabled(dataModel) {
                if (!dataModel) {
                    return false;
                }

                return !this.articles.find(a => a.id === dataModel.id);
            }
        },
        computed: {
            articleList() {
                const articleRelatedArticles = this.article.articles ? this.article.articles.filter(a => !!a) : [];

                if (!articleRelatedArticles.length) {
                    return this.articles;
                }

                const articles = _.cloneDeep(this.articles);


                articles.push(
                    ...articleRelatedArticles.map(a => {
                        const translation = a.translations ? a.translations.find(t => t.language === this.language.code) : a.title;

                        return {
                            id: a.id,
                            title: translation ? translation.content : null,
                            business_units: a.business_units.map(b => b.name)
                        };
                    }).filter(a => !!a.title)
                );

                return articles;
            },
        },

        watch: {
            article() {
                this.setArticles();
            },
            language() {
                this.getArticles();
            }
        },

        beforeMount() {
            this.getArticles();
        },
    };
</script>
