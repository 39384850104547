export default {
    general: {
        yes: 'ja',
        no: 'nee',
        settings: 'Instellingen',
        division: 'Divisie',
        status: 'Status',
        search: 'Zoeken',
    },
    navigation: {
        instructions: 'Instructies',
        news: 'Nieuws',
        documents: 'Documenten',
        users: 'Gebruikers',
        contacts: 'Contacten',
        events: 'Evenementen',
        push_notification: 'Push notificatie',
        statistics: 'Statistieken',
        ideabox: 'Ideeënbus',
        business_units: 'Divisies',
        locations_on_route: 'Locaties onderweg',
        furloughs: 'Verlof',
        roles: 'Rollen',
        log_out: 'Uitloggen',
        my_service_schedule: 'mijnDienstrooster',
        departments: 'Afdelingen',
        language_switch: {
            title: 'Taal',
            languages: {
                nl: 'Nederlands',
                en: 'Engels',
                de: 'Duits',
            },
        },
    },
    languages: {
        nl: 'Nederlands',
        en: 'Engels',
        de: 'Duits',
        fr: 'Frans',
        pl: 'Pools',
    },
    locations_on_route: {
        data: 'Gegevens',
        locations_on_route: 'Locaties onderweg',
        select_language: 'Selecteer taal',
        add_location: 'Locatie toevoegen',
        title: 'Titel',
        division: 'Divisie',
        divisions: 'Divisies',
        change_location: 'Wijzig locatie',
        save_changes: 'Wijzigingen opslaan',
        add_new_location: 'Nieuwe locatie toevoegen',
        select_division: 'Selecteer een divisie',
        no_results: 'Geen resultaten',
        button_text: 'Button tekst',
        button_url: 'Button url',
        description: 'Beschrijving',
        location_saved: 'Locatie is opgeslagen',
        delete: 'Verwijderen',
        location_delete_failed: 'Verwijderen mislukt, probeer het later opnieuw',
    },
    ab_component: {
        component_delete: 'Component verwijderen',
        title_big: 'Titel groot',
        title_small: 'Titel klein',
        textbox: 'Tekstvak',
        add_pdf: 'Voeg een pdf toe',
        preview_pdf: 'Preview PDF',
        delete_pdf: 'PDF verwijderen',
        pdf_name: 'PDF naam',
        or: 'of',
        poll_question: 'Vraag',
        poll_answer: 'Antwoord',
        poll_add_answer: 'Antwoord toevoegen',
    },
    upload_component: {
        add_files: 'Sleep hier bestanden in',
        or: 'of',
        add_image: 'Voeg een afbeelding toe',
        add_pdf: 'Voeg een PDF toe',
        delete_image: 'Afbeelding verwijderen',
        image_to_large: 'Upload afbeeldingen kleiner dan 22mb',
    },
    article_app: {
        news_article: 'Nieuwsartikel',
        tip_article: 'Tip artikel',
        preview: 'Voorbeeld',
        delete_article: 'Artikel verwijderen',
        publiceren: 'Publiceren',
        save: 'Opslaan',
        to_concept: 'Naar concept omzetten',
        article_published_flash: 'Artikel gepubliceerd',
        article_save_flash: 'Artikel opgeslagen',
        article_duplicated_flash: 'Artikel wordt gedupliceerd, dit kan een minuut duren.',
        article_translated_flash: 'Artikel wordt vertaald, dit kan een minuut duren.',
        article_concept_flash: 'Artikel naar concept omgezet',
        article_title_image_missing: 'Titel of hoofdafbeelding is nog niet ingevoerd',
        article_select_language: 'Selecteer taal',
        duplicate: 'Dupliceren',
        translate_dutch: 'Vertalen naar het Nederlands',
        translate_english: 'Vertalen naar het Engels',
        translate_german: 'Vertalen naar het Duits',
        translate_french: 'Vertalen naar het Frans',
        translate_polish: 'Vertalen naar het Pools',
        publish_date_future: 'De publicatiedatum moet minstens 15 minuten in de toekomst zijn.',
    },
    article_related_articles: {
        related_articles: 'Gerelateerde artikelen',
        article_one: 'Artikel 1',
        article_two: 'Artikel 2',
        article_three: 'Artikel 3',
        no_results: 'Geen resultaten',
        select_article: 'Selecteer artikel',
        all_divisions: 'Alle divisies',
    },
    article_tab_content: {
        general_info: 'Algemene gegevens',
        title: 'Titel',
        primary_image: 'Hoofd afbeelding',
        content: 'Content',
        publish_at: 'Publiceer op',
        select_date: 'Kies een datum',
        article_success_flash: 'Succesvol opgeslagen',
        image_to_large: 'Upload afbeeldingen kleiner dan 22mb',
    },
    article_tab_tags: {
        title: 'Tags toevoegen',
        select: 'Selecteer een of meerdere tags',
    },
    article_tab_division: {
        divisions: 'Divisies',
        no_results: 'Geen resultaten',
        function: 'Functie',
        all_functions: 'Alle functies',
        all_divisions: 'Alle divisies',
        placeholder: 'Selecteer divisie(s)',
    },
    article_tab_profile: {
        linked_profile: 'Gekoppeld profiel',
        title: 'Titel',
        description: 'Beschrijving',
        profile: 'Profiel',
        select_profile: 'Selecteer profiel',
        all_divisions: 'Alle divisies',
        all_departments: 'Alle afdelingen',
    },
    article_tabs: {
        content: 'Content',
        story: 'Verhaal van',
        linked_profile: 'Gekoppeld profiel',
        related_articles: 'Gerelateerde artikelen',
        divisions: 'Divisies',
        push_notification: 'Push notificatie',
    },
    article_push_notification_tabs: {
        send: 'Sturen',
        overview: 'Overzicht',
        information: 'Gegevens',
        push_notification_language: 'Push notificatie Nederlands',
        push_notification: 'Push notificatie',
        date: 'Datum',
        no_push_notifications_yet: 'Er zijn nog geen push notificaties gestuurd voor dit artikel.',
    },
    article_tab_story: {
        profile: 'Profiel',
        select_profile: 'Selecteer profiel',
        story_of: 'Verhaal van',
        all_divisions: 'Alle divisies',
        all_departments: 'Alle afdelingen',
    },
    component_list: {
        add_component: 'Component toevoegen',
        title_big: 'Titel groot',
        title: 'Titel',
        textbox: 'Tekstvak',
        image: 'Afbeelding',
        pdf: 'PDF',
        poll: 'Poll',
        saved_success_flash: 'Succesvol opgeslagen',
    },
    delete_article_modal: {
        delete_article: 'Wil je dit artikel verwijderen?',
        warning: 'Dit kan niet ongedaan worden gemaakt.',
        cancel: 'Annuleren',
        delete: 'Verwijderen',
    },
    delete_chapter_modal: {
        delete_chapter: 'Hoofdstuk verwijderen',
        warning: 'Wil je dit hoofdstuk echt verwijderen? Dit kan niet ongedaan worden gemaakt.',
        cancel: 'Annuleren',
        delete: 'Verwijderen',
    },
    delete_component_modal: {
        delete_component: 'Wil je dit component verwijderen?',
        warning: 'Dit kan niet ongedaan worden gemaakt.',
        cancel: 'Annuleren',
        delete: 'Verwijderen',
    },
    delete_doc_modal: {
        delete_doc: 'Wil je dit document verwijderen?',
        warning: 'Dit kan niet ongedaan worden gemaakt.',
        cancel: 'Annuleren',
        delete: 'Verwijderen',
    },
    delete_map_modal: {
        delete_map: 'Wil je deze map verwijderen?',
        warning: 'Dit kan niet ongedaan worden gemaakt.',
        cancel: 'Annuleren',
        delete: 'Verwijderen',
    },
    delete_paragraph_modal: {
        delete_paragraph: 'Wil je deze paragraaf verwijderen?',
        warning: 'Dit kan niet ongedaan worden gemaakt.',
        cancel: 'Annuleren',
        delete: 'Verwijderen',
    },
    delete_sub_paragraph_modal: {
        delete_sub_paragraph: 'Wil je deze subparagraaf verwijderen?',
        warning: 'Dit kan niet ongedaan worden gemaakt.',
        cancel: 'Annuleren',
        delete: 'Verwijderen',
    },
    no_language_modal: {
        notification: 'Kan de wijzigingen niet opslaan',
        argument: 'Er is geen taal geselecteerd.',
        ok: 'OK',
    },
    documents: {
        date_agreed: 'Datum akkoord',
        agreed: 'Akkoord',
        disagreed: 'Niet akkoord',
        show_all: 'Toon alles',
    },
    documents_tabs: {
        content: 'Content',
        agree: 'Akkoord geven',
    },
    documents_add_doc: {
        add_new_document: 'Nieuw document toevoegen',
        add_document: 'Document toevoegen',
        data: 'Gegevens',
        title: 'Titel',
        file: 'Bestand',
        drag_file: 'Sleep hier een bestand in',
        or: 'of',
        add_file: 'Voeg een bestand toe',
        delete_file: 'Bestand verwijderen',
        replace_file: 'Bestand vervanen',
        has_to_accept: 'Akkoord geven',
    },
    documents_add_map: {
        add_new_map: 'Nieuwe map toevoegen',
        add_map: 'Map toevoegen',
        data: 'Gegevens',
        title: 'Titel',
        divisions: 'Divisies',
        no_results: 'Geen resultaten',
        function: 'Functie',
        language: 'Taal',
        select_language: 'Selecteer taal',
        select_function: 'Selecteer functie',
        all_divisions: 'Alle divisies',
        select_map: 'Selecteer een map',
    },
    documents_add_link: {
        add_link: 'Link toevoegen',
        data: 'Gegevens',
        link: 'Link',
        title: 'Titel',
        example_url: 'bijv. https://www.abtexelgroup.com/wagenpark',
    },
    documents_app: {
        add_document: 'Document toevoegen',
        add_folder: 'Map toevoegen',
        add_link: 'Link toevoegen',
        title: 'Titel',
        last_changed: 'Laatst gewijzigd',
        search_placeholder: 'Zoeken...',
        no_searchresults: 'Geen zoekresultaten',
    },
    documents_edit_doc: {
        edit_doc: 'Document bewerken',
        delete_doc: 'Document verwijderen',
        save_changes: 'Wijzigingen opslaan',
        data: 'Gegevens',
        title: 'Titel',
        file: 'Bestand',
        drag_file: 'Sleep hier een bestand in',
        or: 'of',
        add_file: 'Voeg een bestand toe',
        delete_file: 'Bestand verwijderen',
        replace_file: 'Bestand vervangen',
        preview_pdf: 'Preview PDF',
        image_to_large: 'Upload afbeeldingen kleiner dan 22mb',
    },
    documents_edit_link: {
        edit_link: 'Link bewerken',
        delete_link: 'Link verwijderen',
        save_changes: 'Wijzigingen opslaan',
        data: 'Gegevens',
        title: 'Titel',
    },
    documents_edit_map: {
        edit_folder: 'Map bewerken',
        delete_folder: 'Map verwijderen',
        save_changes: 'Wijzigingen opslaan',
        data: 'Gegevens',
        title: 'Titel',
        divisions: 'Divisies',
        no_results: 'Geen resultaten',
        select_language: 'Selecteer taal',
        language: 'Taal',
        all_functions: 'Alle functies',
        all_divisions: 'Alle divisies',
        function: 'Functie',
    },
    manual_app: {
        deadline: 'Kies a.u.b. een deadline',
    },
    manual_chapter: {
        delete_chapter: 'Hoofdstuk verwijderen',
        preview: 'Preview',
        add_paragraph: 'Paragraaf toevoegen',
    },
    manual_component: {
        saved_success: 'Succesvol opgeslagen',
    },
    manual_header: {
        instructions: 'Instructies',
        basic_manual: 'Basis handboek',
        business_unit_specific: 'Business unit specifiek',
        function_specific: 'Functie specifiek',
        publish: 'Publiceren',
        select_language: 'Selecteer taal',
        version: 'Versie',
    },
    manual_google_translate: {
        started: 'Vertalen is gestart',
        translate_to_english: 'Vertalen naar Engels',
        automatic_translation: 'Automatisch vertalen',
        automatic_translation_warning: 'Weet je zeker dat dit je deze instructie wilt vertalen naar Engels? De huidige content voor deze taal wordt overschreven. Het genereren van de vertaling kan een aantal minuten duren.',
        translate: 'Vertalen',
    },
    manual_paragraph: {
        delete_paragraph: 'Paragraaf verwijderen',
        add_subsection: 'Subparagraaf toevoegen',
    },
    manual_subsection: {
        delete_subsection: 'Subparagraaf verwijderen',
    },
    manual_tab_adjustments: {
        delete_subsection: 'Subparagraaf verwijderen',
        adjustments: 'Aanpassingen',
        deadline: 'Deadline akkoord geven',
        pick_date: 'Kies een datum',
        contactperson: 'Contactpersoon',
    },
    manual_tab_confirmed: {
        confirmed: 'Akkoord',
        not_confirmed: 'Niet akkoord',
        show_all: 'Toon alles',
        title: 'Titel',
        type: 'Type',
        date_agree: 'Datum akkoord',
        phone: 'Telefoonnummer',
        no_results: 'Geen resultaten',
        business_unit: 'Divisie',
    },
    manual_tab_content: {
        add_chapter: 'Hoofdstuk toevoegen',
        search: 'Zoeken...',
        chapters: 'Hoofdstukken',
    },
    manual_tab_divisions: {
        divisions: 'Divisies',
        no_results: 'Geen resultaten',
        function: 'Functie',
        all_divisions: 'Alle divisies',
        all_functions: 'Alle functies',
        placeholder: 'Selecteer divisie(s)',
    },
    manual_tabs: {
        content: 'Content',
        adjustments: 'Aanpassingen',
        agree: 'Akkoord geven',
        divisions: 'Divisies',
    },
    new_chapter_modal: {
        add_chapter: 'Hoofdstuk toevoegen',
        chapter_name: 'Naam hoofdstuk',
    },
    new_paragraph_modal: {
        add_paragraph: 'Paragraaf toevoegen',
        name_paragraph: 'Naam paragraaf',
    },
    news_app: {
        news: 'Nieuws',
        tip_article: 'Tip artikel toevoegen',
        add_article: 'Nieuwsartikel toevoegen',
        search: 'Zoeken...',
        title: 'Titel',
        type: 'Type',
        last_edited: 'Laatst gewijzigd',
        planned_publication: 'Geplande publicatie',
        posted: 'Geplaatst',
        no_results: 'Geen zoekresulaten',
    },
    new_subsection_modal: {
        add_subsection: 'Subparagraaf toevoegen',
        name_subsection: 'Naam subparagraaf',
    },
    statistics_app: {
        statistics: 'Statistieken',
        login: 'Inloggen',
        news: 'Nieuws',
        fuelReports: 'Brandstof rapportages',
    },
    statistics_app_fuel_reports: {
        export: 'Exporteren',
        reports_export: 'Brandstof rapportages statistieken overzicht exporteren',
        reports_opened: 'Brandstof rapportages geopend',
        opened_number: 'Aantal keer geopend per maand',
        opened_number_second: 'Aantal keer geopend',
        month: 'Maand',
    },
    statistics_app_logins: {
        login_number: 'Aantal ingelogd per week',
        week: 'Week',
        login_number_second: 'Aantal keer ingelogd',
        unique_login_number: 'Aantal uniek ingelogd per week',
    },
    statistics_app_news: {
        news_opened: 'Geopende nieuwsartikelen',
        news_article: 'Nieuwsartikel',
        opened: 'Aantal keer geopend',
        select_language: 'Selecteer taal',
        published_at: 'Publicatiedatum',
    },
    switch_confirmation_modal: {
        save_changes: 'Wijzigingen opslaan',
        save_confirmation: 'Wil je deze wijzigingen opslaan?',
        yes: 'Ja',
        no: 'Nee',
    },
    article_tab_push_notifications: {
        push_notification: 'Push notificatie',
        push_notification_dutch: 'Push notificatie Nederlands',
        push_notification_polish: 'Push notificatie Pools',
        push_notification_english: 'Push notificatie Engels',
        push_notification_german: 'Push notificatie Duits',
        push_notification_french: 'Push notificatie Frans',
        send_notification: 'Verstuur notificatie',
        save_notification: 'Opslaan',
        message: 'Je verstuurt alleen een push notificatie als het veld is ingevuld. Voorbeeld: Als alleen het veld voor Nederlands is ingevuld, dan ontvangen alleen Nederlandse gebruikers een notificatie',
        flash_message: 'Push notificatie verzonden',
        flash_message_save: 'Push notificatie opgeslagen',
    },
    add_chapter_link_modal: {
        select_modal: 'Selecteer handleiding',
        label: 'Label',
        manual: 'Handleiding',
        chapter: 'Hoofdstuk',
        paragraph: 'Paragraaf',
        cancel: 'Annuleren',
        insert: 'Invoegen',
    },
    add_article_link_modal: {
        info: 'Koppel naar artikel',
        label: 'Label',
        article: 'Artikel',
        insert: 'Invoegen',
        select: 'Selecteer een artikel',
    },
    manual_component_list: {
        add_component: 'Component toevoegen',
        title_big: 'Titel groot',
        title: 'Titel',
        textbox: 'Tekstvak',
        image: 'Afbeelding',
    },
    delete_contact_person_confirmation_modal: {
        h1: 'Contact persoon verwijderen?',
        paragraph: 'Dit kan niet ongedaan worden gemaakt.',
        no: 'Nee',
        yes: 'Ja',
    },
    contact_form: {
        has_whatsapp: 'Whatsapp',
        delete_contact: 'Verwijder contact',
        change_contact: 'Contact wijzigen',
        add_contact: 'Nieuw contact toevoegen',
        data: 'Gegevens',
        divisions: 'Divisies',
        no_results: 'Geen resultaten',
        department: 'Afdeling',
        title: 'Titel',
        subtitle: 'Subtitel',
        phone: 'Vast telefoonnummer',
        mobile_phone: 'Mobiel nummer',
        email: 'E-mailadres',
        language: 'Taal',
        picture: 'Foto',
        contactpersons: 'Contactpersonen',
        add_contactperson: 'Extra contactpersoon toevoegen',
        save_changes: 'Wijzigingen opslaan',
        select_language: 'Selecteer taal',
        contactperson_saved: 'Contactpersoon opgeslagen',
        contactperson_deleted: 'Contactpersoon verwijderd',
        language_required: 'Taal is verplicht',
        invalid_phone: 'Ongeldig telefoonnummer',
        invalid_mobile_phone: 'Ongeldig mobiel telefoonnummer',
    },
    contact_person: {
        name: 'Naam',
        picture: 'Foto',
        contactperson_deleted: 'Contactpersoon verwijderd',
        save_changes: 'Wijzigingen opslaan',
        add_contact: 'Contact toevoegen',
    },
    contact_index: {
        add_contact: 'Contact toevoegen',
        title: 'Titel',
        subtitle: 'Subtitel',
        phonenumber: 'Telefoonnummer',
        email: 'E-mailadres',
        departments: 'Afdelingen',
        divisions: 'Divisies',
        no_results: 'Geen resultaten',
        placeholder_departments: 'Selecteer afdeling',
        placeholder_divisions: 'Selecteer divisie',
    },
    event_form: {
        change_event: 'Evenement wijzigen',
        add_new_event: 'Nieuw evenement toevoegen',
        add_event: 'Evenement toevoegen',
        save_changes: 'Wijzigingen opslaan',
        data: 'Gegevens',
        title: 'Titel',
        date_of: 'Datum van',
        time_of: 'Tijd van',
        date_till: 'Datum tot',
        time_till: 'Tijd tot',
        date_registration_deadline: 'Datum inschrijfdeadline',
        time_registration_deadline: 'Tijd inschrijfdeadline',
        max_participants: 'Max. aantal deelnemers (incl. eventueel introducés) (optioneel)',
        location: 'Locatie',
        address: 'Adres',
        google_maps_url: 'Google maps URL',
        picture: 'Foto',
        description: 'Beschrijving',
        divisions: 'Divisies',
        no_results: 'Geen resultaten',
        function: 'Functie',
        text_push_notification: 'Tekst push notificatie',
        send_notification: 'Verstuur notificatie naar gebruikers in de geselecteerde divisies en functies',
        is_featured: 'Is uitgelicht',
        with_partner: 'Incl. partner',
        present: 'Aanwezig',
        to_go: '{amount} gaan',
        event_saved: 'Evenement opgeslagen',
        event_notification_send: 'Event notificatie verzonden',
        select_language: 'Selecteer taal',
        pick_date: 'Kies een datum',
    },
    events: {
        events: 'Evenementen',
        add_event: 'Evenement toevoegen',
        title: 'Titel',
        date: 'Datum',
        location: 'Locatie',
    },
    publish_manual_version_confirmation_modal: {
        publish: 'Handleiding publiceren?',
        no: 'Nee',
        yes: 'Ja',
    },
    push_notifications: {
        notifications: 'Notificaties',
        pay_slip_notification: 'Loonstrook notificatie',
        wage_supplement_notification: 'Loonbijlage notificatie',
        data: 'Gegevens',
        push_notification_dutch: 'Push notificaties Nederlands',
        push_notification_polish: 'Push notificaties Pools',
        push_notification_english: 'Push notificaties Engels',
        push_notification_german: 'Push notificaties Duits',
        only_afas_users: 'Alleen medewerkers in loondienst bij AB Texel notificatie versturen',
        divisions: 'Divisies',
        no_results: 'Geen resultaten',
        send_notification: 'Verstuur notificatie',
        all_divisions: 'Alle divisies',
        choose: 'Kies divisies',
        push_notification_send: 'Push notificatie verzonden',
        overview: 'Overzicht',
        search: 'Zoeken',
        push_notificatie_dutch: 'Push notificatie Nederlands',
        date: 'Datum',
        afas_users: 'Alleen AFAS gebruikers',
    },
    personal_document: {
        expiration_date: 'Verloopdatum',
        delete: 'verwijderen',
        pick_a_date: 'Kies een datum',
        front: 'Voorkant',
        back: 'Achterkant',
        document_upload: 'Document uploaden',
        certificates: 'Certificaten',
        name_certificate: 'Naam certificaat',
        add_certificate: 'Certificaat toevoegen',
        delete_document_type: 'Document type verwijderen',
        add_type: 'Type toevoegen',
    },
    users: {
        users: 'Gebruikers',
        add_user: 'Gebruiker toevoegen',
        search: 'Zoeken...',
        name: 'Naam',
        division: 'Divisie',
        function: 'Functie',
        phone: 'Telefoonnummer',
        no_results: 'Geen resultaten',
        not_available: 'Niet beschikbaar',
        accepted_terms: 'Voorwaarden geaccepteerd',
        last_login: 'Laatst ingelogd',
    },
    user_docs: {
        edit_user: 'Gebruiker wijzigen',
        add_user: 'Nieuwe gebruiker toevoegen',
        general_data: 'Algemene gegevens',
        documents: 'Documenten',
        document_types: 'Document types',
    },
    user_form: {
        general_data: 'Algemene gegevens',
        documents: 'Documenten',
        edit_user: 'Gebruiker bewerken',
        add_new_user: 'Nieuwe gebruiker toevoegen',
        add_user: 'Gebruiker toevoegen',
        save_changes: 'Wijzigingen opslaan',
        data: 'Gegevens',
        afas_number: 'AFAS nummer',
        old_afas_number: 'Oude AFAS nummer',
        name: 'Naam',
        email: 'E-mailadres',
        divisions: 'Divisies',
        no_results: 'Geen resultaten',
        function: 'Functie',
        phone: 'Telefoonnummer',
        street: 'Straat',
        number: 'Huisnummer',
        city: 'Plaats',
        zip_code: 'Postcode',
        country: 'Land',
        employment_start_date: 'Datum indienst',
        employment_end_date: 'Datum uit dienst',
        active_user: 'Actieve gebruiker',
        is_administrator: 'Is beheerder?',
        view_news_items: 'Inzien nieuwsberichten, documenten, handboeken en contactpersonen',
        news_from_division: 'Nieuws, documenten, handboeken en contacten van divisie(s)',
        available_payslips: 'Beschikbare loonstroken',
        no_payslips: 'Geen loonstroken beschikbaar',
        available_wage_supplements: 'Beschikbare loonbijlagen',
        no_wage_supplements: 'Geen loonbijlagen aanwezig',
        user_saved: 'Gebruiker opgeslagen',
        roles: 'Rollen',
        visible_anniversary: 'Jubilea en in en uit dienst zichtbaar',
        employee_number: 'Personeelnummer',
        invalid_phone: 'Ongeldig telefoonnummer',
    },
    roles: {
        roles: 'Rollen',
        add_role: 'Rol toevoegen',
        search: 'Zoeken...',
        name: 'Titel',
        divisions: 'Divisie',
        permissions: 'Rechten',
        no_results: 'Geen resultaten',
    },
    role_form: {
        data: 'Algemene gegevens',
        name: 'Titel',
        divisions: 'Divisies',
        permissions_label: 'Rechten',
        add_role: 'Rol toevoegen',
        change_role: 'Rol bijwerken',
        delete_role: 'Verwijderen',
        role_saved: 'Rol bijgewerkt',
        role_deleted: 'Rol verwijderd',
        save_changes: 'Wijzigingen opslaan',
        forbidden_message: 'Rol kan niet worden bijgewerkt of verwijderd',
        permissions: {
            'add-and-edit-instructions': 'Toevoegen en wijzigen van instructies',
            'add-and-edit-news-items': 'Toevoegen en wijzigen van nieuwsberichten',
            'add-and-edit-documents': 'Toevoegen en wijzigen van documenten',
            'add-and-edit-users': 'Toevoegen en wijzigen van gebruikers',
            'add-and-edit-contacts': 'Toevoegen en wijzigen van contacten',
            'add-and-edit-events': 'Toevoegen en wijzigen van evenementen',
            'manage-push-notifications': 'Beheer van push notificaties',
            'assess-expenses': 'Beoordelen van onkosten',
            'see-statistics': 'Inzage van statistieken',
            'add-and-edit-roles': 'Toevoegen en wijzigen van rollen',
            'edit-divisions': 'Wijzigen van divisies',
            'see-ideabox': 'Inzage van ideeënbus',
            'manage-furloughs': 'Beoordeel verlofaanvragen',
            'edit-my-service-roster': 'Inzage mijnDienstrooster',
            'add-and-edit-locations': 'Toevoegen en wijzigen van locaties onderweg',
            'add-and-edit-departments': 'Toevoegen en wijzigen van afdelingen',
            'view-poll-results': 'Inzien poll resultaten binnen ab-connect',
            'change-division-or-function': 'Verander van divisie of functie binnen ab-connect',
        },
    },
    ideabox: {
        header: 'Ideeënbus',
        search: 'Zoeken...',
        status: {
            open: 'open',
            doing: 'in behandeling',
            done: 'afgehandeld',
        },
    },
    furloughs: {
        title: 'Verlof',
        applicant: 'Aanvrager',
        from: 'Van',
        to: 'Tot',
        status: 'Status',
        fleethours: 'Fleethours',
        statuses: {
            1: 'openstaand',
            2: 'goedgekeurd',
            3: 'afgekeurd',
        },
        detail: {
            title: 'Verlof detail',
            data: 'Gegevens',
            explanation: 'Toelichting',
            is_processed_fleethours: 'Is verwerkt in FleetHours',
            saved: 'Opgeslagen',
        },
    },
    business_units: {
        business_unit: 'Divisie',
        business_units: 'Divisies',
        business_unit_management: 'Divisie beheer',
        saved: 'Succesvol gewijzigd',
        permissions_label: 'App permissies',
        general_label: 'Algemene gegevens',
        emails_label: 'E-mailadressen',
        anniversary_item: 'Jubilea item',
        anniversaries_label: 'Jubilea',
        add_anniversary: 'Extra jubilea item toevoegen',
        export_label: 'Export',
        exports: {
            type: 'Type',
            export: 'Exporteren',
            types: {
                accepted_terms_last_login: 'Voorwaarden geaccepteerd / laatst ingelogd',
            },
        },
        general: {
            name: 'Naam',
        },
        emails: {
            expenses_email: 'Emailadres(sen) voor onkosten.',
            furlough_planning_email: 'Emailadres(sen) voor planning (Verlof).',
            furlough_hours_control_email: 'Emailadres(sen) voor urencontrole (Verlof).',
            comma_seperated: 'Kommagescheiden voor meerdere adressen. VB: (info@example.com, mail@example.com)',
        },
        permissions: {
            'tanklocaties-wasstraten': 'Locaties op de route',
            furlough: 'Verlof',
            expenses: 'Onkosten',
            payslips: 'Salaris',
            work_clothing: 'Werkkleding',
        },
    },
    redactor_custom_buttons: {
        article_link: 'Koppel artikel',
        manual_link: 'Koppel handboek',
    },
    content_page: {
        changes_couldnt_be_saved: 'Wijzigingen konden niet worden opgeslagen',
        changes_saved_successfully: 'Wijzigingen succesvol opgeslagen',
        content_couldnt_be_loaded: 'Inhoud kon niet worden geladen',
    },
    departments: {
        departments: 'Afdelingen',
        add_department: 'Afdeling toevoegen',
        save_changes: 'Afdeling wijzigen',
        title: 'Titel',
        data: 'Gegevens',
        name_nl: 'Naam Nederlands',
        name_en: 'Naam Engels',
        name_pl: 'Naam Pools',
        name_de: 'Naam Duits',
        name_fr: 'Naam Frans',
        delete: 'Verwijder afdeling',
        none_found: 'Geen afdelingen gevonden',
    },
    phone_number: {
        invalid_phone: 'Het telefoon nummer klopt niet volgens de validatie.',
    },
};

/* eslint-disable no-undef */
(function ($R) {
    $R.lang.nl = {
        format: 'Format',
        image: 'Afbeelding',
        file: 'Bestand',
        link: 'Link',
        bold: 'Vet',
        italic: 'Cursief',
        deleted: 'Doorstreept',
        underline: 'Onderstreept',
        superscript: 'Superscript',
        subscript: 'Subscript',
        'bold-abbr': 'B',
        'italic-abbr': 'I',
        'deleted-abbr': 'S',
        'underline-abbr': 'U',
        'superscript-abbr': 'Sup',
        'subscript-abbr': 'Sub',
        lists: 'Lijsten',
        'link-insert': 'Link invoegen',
        'link-edit': 'Link Bewerken',
        'link-in-new-tab': 'Open link in nieuw tabblad',
        unlink: 'Ontkoppelen',
        cancel: 'Annuleren',
        close: 'Afsluiten',
        insert: 'Invoegen',
        save: 'Opslaan',
        delete: 'Verwijder',
        text: 'Tekst',
        edit: 'Bewerken',
        title: 'Titel',
        paragraph: 'Normale tekst',
        quote: 'Citaat',
        code: 'Code',
        heading1: 'Koptekst 1',
        heading2: 'Koptekst 2',
        heading3: 'Koptekst 3',
        heading4: 'Koptekst 4',
        heading5: 'Koptekst 5',
        heading6: 'Koptekst 6',
        filename: 'Bestandsnaam',
        optional: 'Optioneel',
        unorderedlist: 'Ongeordende lijst',
        orderedlist: 'Geordende lijst',
        outdent: 'Uitspringen',
        indent: 'Inspringen',
        horizontalrule: 'Streep',
        upload: 'Upload',
        'upload-label': 'Drop files here or click to upload',
        'upload-change-label': 'Drop a new image to change',
        'accessibility-help-label': 'RTF editor',
        caption: 'Caption',
        bulletslist: 'Ongeordende lijst',
        numberslist: 'Genummerde lijst',
        'image-position': 'Position',
        none: 'None',
        left: 'Left',
        right: 'Right',
        center: 'Center',
        undo: 'Undo',
        redo: 'Redo',
        table: 'Tabel',
        linkreference: 'Link hoofdstuk',
    };
}(Redactor));
/* eslint-enable no-undef */
