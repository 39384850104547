import MyServiceSchedule from '../components/ContentPage/MyServiceSchedule';

export default [
    {
        path: '/mijn-dienst-rooster',
        name: 'my-service-schedule',
        component: MyServiceSchedule,
        meta: {
            permissions: ['edit-my-service-roster'],
        },
    },
];
