<template>
    <div class="content">
        <div class="header">
            <h1>Contacten</h1>

            <div class="header-buttons">
                <div @click="createContact">
                    <div class="button green">
                        <span>{{ $t('contact_index.add_contact') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="body">
            <div class="filter-bar">
                <div class="search">
                    <label for="search">
                        <img alt="search" src="img/search.svg">
                    </label>

                    <input id="search" :placeholder="$t('users.search')" type="text" v-model="search" @keyup="onSearch" />
                </div>

                <div class="departments-filter">
                    <label for="departments">{{ $t('contact_index.departments') }}</label>
                    <vue-multiselect
                        id="departments"
                        v-model="selectedDepartments"
                        v-bind="config"
                        :placeholder="$t('contact_index.placeholder_departments')"
                        :close-on-select="false"
                        :multiple="true"
                        :options="departments"
                        :searchable="false"
                        @close="filterDepartments">
                        <template slot="tag" slot-scope="props">
                            <span v-text="props.option.name + ', '"></span>
                        </template>
                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                                <img alt="check" class="selected" src="/svg/check.svg">
                            </div>
                        </template>
                        <span slot="noResult">{{ $t('contact_index.no_results') }}</span>
                    </vue-multiselect>
                </div>
                <div class="divisions-filter">
                    <label for="divisions">{{ $t('contact_index.divisions') }}</label>
                    <vue-multiselect
                        id="divisions"
                        v-model="selectedBusinessUnits"
                        v-bind="config"
                        :placeholder="$t('contact_index.placeholder_divisions')"
                        :close-on-select="false"
                        :multiple="true"
                        :options="businessUnits"
                        :searchable="false"
                        @close="filterBusinessUnits">
                        <template slot="tag" slot-scope="props">
                            <span v-text="props.option.name + ', '"></span>
                        </template>
                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                                <img alt="check" class="selected" src="/svg/check.svg">
                            </div>
                        </template>
                        <span slot="noResult">{{ $t('contact_index.no_results') }}</span>
                    </vue-multiselect>
                </div>
                <div class="trash-bin" @click="removeFilters()">
                    <img alt="delete-filters" src="svg/trash-bin.svg">
                </div>

            </div>

            <table>
                <thead>
                    <tr>
                        <th :class="{'sorted': orderByColumn === 'title'}" @click="orderBy('title')">{{ $t('contact_index.title') }}</th>
                        <th :class="{'sorted': orderByColumn === 'sub_title'}" @click="orderBy('sub_title')">{{ $t('contact_index.subtitle') }}</th>
                        <th :class="{'sorted': orderByColumn === 'phone'}" @click="orderBy('phone')">{{ $t('contact_index.phone') }}</th>
                        <th :class="{'sorted': orderByColumn === 'email'}" @click="orderBy('email')">{{ $t('contact_index.email') }}</th>
                        <th>{{ $t('contact_index.departments') }}</th>
                        <th>{{ $t('contact_index.divisions') }}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="contact in contacts" :key="'contact_' + contact.id" @click="editContact(contact)">
                        <td>{{ contact.title }}</td>
                        <td>{{ contact.sub_title }}</td>
                        <td>{{ contact.phone }}</td>
                        <td>{{ contact.email }}</td>
                        <td>{{ contact.departments.map(f => f.name).join(', ') }}</td>
                        <td>{{ contact.business_units.map(b => b.name).join(', ') }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { debounce } from 'lodash';
    import VueMultiselect from 'vue-multiselect/src/Multiselect.vue';
    import { BusinessUnitService } from '../../services';

    export default {
        name: 'contacts-index',

        components: {
            VueMultiselect,
        },

        data() {
            return {
                config: {
                    label: 'name',
                    'track-by': 'id',
                    selectLabel: '',
                    selectedLabel: '',
                    deselectLabel: '',
                },
                businessUnits: [],
                selectedBusinessUnits: [],
                departments: [],
                selectedDepartments: [],
                contacts: [],
                search: '',
                orderByColumn: null,
                orderByDirection: 'asc',
            };
        },

        beforeMount() {
            this.search = this.$store.getters['contacts/searchQuery'];
            this.selectedDepartments = this.$store.getters['contacts/selectedDepartments'];
            this.selectedBusinessUnits = this.$store.getters['contacts/selectedBusinessUnits'];

            this.fetchContacts();
            this.fetchDepartments();
            this.fetchDivisions();
        },

        methods: {
            onSearch: debounce(function () {
                this.$store.commit('contacts/setSearchQuery', this.search);
                this.fetchContacts();
            }, 500),

            createContact() {
                this.$router.push({ name: 'contacts.create' });
            },

            editContact(contact) {
                this.$router.push({ name: 'contacts.edit', params: { id: contact.id } });
            },

            fetchContacts() {
                const promise = window.axios.get('/contacts', {
                    params: {
                        ...(this.search) && { search: this.search },
                        ...(this.orderByColumn) && { orderBy: this.orderByColumn },
                        ...(this.orderByColumn) && { orderByDirection: this.orderByDirection },
                        ...(this.selectedDepartments.length) && { departments: this.selectedDepartments.map(d => d.id) },
                        ...(this.selectedBusinessUnits.length) && { businessUnits: this.selectedBusinessUnits.map(b => b.id) },
                    },
                });

                promise.then((response) => {
                    // response.data contains contacts, but the list can contain multiple contacts with the same id.
                    // We want to have a list of unique contacts. So we use a Set to filter out the duplicates.
                    this.contacts = [...new Set(response.data.map(c => c.id))].map(id => response.data.find(c => c.id === id));
                });
            },

            fetchDepartments() {
                const promise = window.axios.get('/departments');

                promise.then((response) => {
                    this.departments = response.data.data;
                });
            },

            fetchDivisions() {
                BusinessUnitService.fetchByPermission(this.$permissions.ADD_AND_EDIT_CONTACTS)
                    .then((response) => this.businessUnits = response.data)
            },

            filterDepartments() {
                this.$store.commit('contacts/setSelectedDepartments', this.selectedDepartments);
                this.fetchContacts();
            },

            filterBusinessUnits() {
                this.$store.commit('contacts/setSelectedBusinessUnits', this.selectedBusinessUnits);
                this.fetchContacts();
            },

            removeFilters() {
                this.selectedDepartments = [];
                this.selectedBusinessUnits = [];
                this.search = '';
                this.$store.commit('contacts/setSelectedDepartments', this.selectedDepartments);
                this.$store.commit('contacts/setSelectedBusinessUnits', this.selectedBusinessUnits);
                this.$store.commit('contacts/setSearchQuery', this.search);
                this.fetchContacts();
            },

            orderBy(column) {
                if (column === this.orderByColumn) {
                    this.orderByDirection = this.orderByDirection === 'asc' ? 'desc' : 'asc';
                    this.fetchContacts();
                    return;
                }

                this.orderByColumn = column;
                this.orderByDirection = 'asc';
                this.fetchContacts();
            },
        },
    };
</script>

<style lang="scss">
    .filter-bar {
        display        : flex;
        align-items    : flex-end;
        flex-direction : row;
        gap            : 1rem;

        .search {
            width : 28em;
        }

        .departments-filter {
            min-width : 20%;
            max-width : 25%;
        }

        .divisions-filter {
            min-width : 20%;
            max-width : 25%;
        }

        .trash-bin {
            border-radius : 50%;
            padding       : 0.7rem;
            background    : white;
            cursor        : pointer;

        }
    }
</style>