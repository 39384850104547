<template>
    <div class="menu">
        <div class="logo">
            <a href="/">
                <img alt="logo"
                     src="/img/ab-logo.svg">
            </a>
        </div>
        <div class="menu-items">
            <router-link to="/instructies" v-if="$can('add-and-edit-instructions')">
                <svg fill="none"
                     height="24"
                     width="24"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 4h5v8l-2.5-1.5L6 12V4z"
                        fill="#fff" />
                </svg>
                <span>{{ $t('navigation.instructions') }}</span>
            </router-link>
            <router-link to="/nieuws" v-if="$can('add-and-edit-news-items')">
                <svg fill="none"
                     height="24"
                     viewBox="0 0 24 24"
                     width="24"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M21 19V5C21 3.9 20.1 3 19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19ZM8.5
                        13.5L11 16.51L14.5 12L19 18H5L8.5 13.5Z"
                        fill="#fff" />
                </svg>
                <span>{{ $t('navigation.news') }}</span>
            </router-link>
            <router-link to="/documenten" v-if="$can('add-and-edit-documents')">
                <svg fill="none"
                     height="24"
                     width="24"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM6 4h5v8l-2.5-1.5L6 12V4z"
                        fill="#fff" />
                </svg>
                <span>{{ $t('navigation.documents') }}</span>
            </router-link>
            <router-link to="/gebruikers" v-if="$can('add-and-edit-users')">
                <svg fill="none"
                     height="24"
                     width="24"
                     xmlns="http://www.w3.org/2000/svg">
                    <path clip-rule="evenodd"
                          d="M10.99 8c0 1.66-1.33 3-2.99 3-1.66 0-3-1.34-3-3s1.34-3 3-3 2.99 1.34 2.99 3zm8 0c0 1.66-1.33 3-2.99
                          3-1.66 0-3-1.34-3-3s1.34-3 3-3 2.99 1.34 2.99 3zM8 13c-2.33 0-7 1.17-7
                          3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm7.03.05c.35-.03.68-.05.97-.05 2.33 0 7 1.17 7
                          3.5V19h-6v-2.5c0-1.48-.81-2.61-1.97-3.45z"
                          fill="#fff" />
                </svg>
                <span>{{ $t('navigation.users') }}</span>
            </router-link>
            <router-link to="/contacten" v-if="$can('add-and-edit-contacts')">
                <svg fill="none"
                     height="24"
                     width="24"
                     xmlns="http://www.w3.org/2000/svg">
                    <path clip-rule="evenodd"
                          d="M10.99 8c0 1.66-1.33 3-2.99 3-1.66 0-3-1.34-3-3s1.34-3 3-3 2.99 1.34 2.99 3zm8 0c0 1.66-1.33 3-2.99 3-1.66
                           0-3-1.34-3-3s1.34-3 3-3 2.99 1.34 2.99 3zM8 13c-2.33 0-7 1.17-7
                           3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm7.03.05c.35-.03.68-.05.97-.05 2.33 0 7 1.17
                           7 3.5V19h-6v-2.5c0-1.48-.81-2.61-1.97-3.45z"
                          fill="#fff" />
                </svg>
                <span>{{ $t('navigation.contacts') }}</span>
            </router-link>
            <router-link to="/evenementen" v-if="$can('add-and-edit-events')">
                <svg width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 12H12V17H17V12ZM16 1V3H8V1H6V3H5C3.89 3 3.01 3.9 3.01 5L3 19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3H18V1H16ZM19 19H5V8H19V19Z"
                          fill="white" />
                </svg>
                <span>{{ $t('navigation.events') }}</span>
            </router-link>
            <router-link to="/push-notificatie" v-if="$can('manage-push-notifications')">
                <svg width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.89 22 12 22ZM18 16V11C18 7.93 16.36 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.63 5.36 6 7.92 6 11V16L4 18V19H20V18L18 16Z"
                          fill="white" />
                </svg>
                <span>{{ $t('navigation.push_notification') }}</span>
            </router-link>
            <router-link to="/statistieken" v-if="$can('see-statistics')">
                <svg width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 6L18.29 8.29L13.41 13.17L9.41 9.17L2 16.59L3.41 18L9.41 12L13.41 16L19.71 9.71L22 12V6H16Z"
                          fill="white" />
                </svg>
                <span>{{ $t('navigation.statistics') }}</span>
            </router-link>
            <router-link to="/ideeenbus" v-if="$can('see-ideabox')">
                <svg width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.3533 0V3.80846H12.6469V0H11.3532H11.3533ZM6.07107 1.1445L5.01123 1.88656L7.19529 5.00548L8.25455 4.2634L6.07107 1.1445ZM17.9288 1.1445L15.7454 4.26342L16.8048 5.0055L18.9888 1.88656L17.929 1.1445H17.9288ZM11.9998 4.85048C8.83525 4.85048 6.26993 6.77214 6.26993 9.14298L9.52537 19.0019H14.4748L17.7299 9.14298C17.7299 6.77218 15.1648 4.85048 12.0001 4.85048H11.9998ZM1.70931 5.99346L1.26733 7.20974L4.84431 8.51202L5.28793 7.29632L1.70923 5.99346H1.70931ZM22.2906 5.99346L18.7118 7.29642L19.154 8.51226L22.7327 7.20976L22.2906 5.99346ZM5.11513 11.5536L1.43713 12.5401L1.77085 13.7888L5.44885 12.8037L5.11519 11.5536H5.11513ZM18.8847 11.5536L18.5513 12.8036L22.2293 13.7886L22.5627 12.5399L18.8847 11.5536V11.5536ZM9.45321 19.7206V21.4344H14.547V19.7209H9.45323L9.45321 19.7206ZM9.45321 22.2863V24H14.547V22.2866H9.45323L9.45321 22.2863Z"
                          fill="white" />
                </svg>
                <span>{{ $t('navigation.ideabox') }}</span>
            </router-link>
            <router-link to="/locaties-onderweg" v-if="$can('add-and-edit-locations')">
                <svg width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.71 11.29L12.71 2.29C12.32 1.9 11.69 1.9 11.3 2.29L2.3 11.29C1.91 11.68 1.91 12.31 2.3 12.7L11.3 21.7C11.69 22.09 12.32 22.09 12.71 21.7L21.71 12.7C22.1 12.32 22.1 11.69 21.71 11.29ZM14 14.5V12H10V15H8V11C8 10.45 8.45 10 9 10H14V7.5L17.5 11L14 14.5Z"
                          fill="white" />
                </svg>
                <span>{{ $t('navigation.locations_on_route') }}</span>
            </router-link>
            <router-link to="/rollen" v-if="$can('add-and-edit-roles')">
                <svg width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 8H17V6C17 3.24 14.76 1 12 1C9.24 1 7 3.24 7 6V8H6C4.9 8 4 8.9 4 10V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V10C20 8.9 19.1 8 18 8ZM12 17C10.9 17 10 16.1 10 15C10 13.9 10.9 13 12 13C13.1 13 14 13.9 14 15C14 16.1 13.1 17 12 17ZM15.1 8H8.9V6C8.9 4.29 10.29 2.9 12 2.9C13.71 2.9 15.1 4.29 15.1 6V8Z"
                          fill="white" />
                </svg>
                <span>{{ $t('navigation.roles') }}</span>
            </router-link>
            <router-link to="/divisies" v-if="$can('edit-divisions')">
                <svg width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_4031_355)">
                        <path d="M6 13C3.8 13 2 14.8 2 17C2 19.2 3.8 21 6 21C8.2 21 10 19.2 10 17C10 14.8 8.2 13 6 13ZM12 3C9.8 3 8 4.8 8 7C8 9.2 9.8 11 12 11C14.2 11 16 9.2 16 7C16 4.8 14.2 3 12 3ZM18 13C15.8 13 14 14.8 14 17C14 19.2 15.8 21 18 21C20.2 21 22 19.2 22 17C22 14.8 20.2 13 18 13Z"
                              fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_4031_355">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <span>{{ $t('navigation.business_units') }}</span>
            </router-link>
            <router-link to="/verlof" v-if="$can('manage-furloughs')">
                <svg width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.31 17L11.75 14.56L14.19 17L15.25 15.94L12.81 13.5L15.25 11.06L14.19 10L11.75 12.44L9.31 10L8.25 11.06L10.69 13.5L8.25 15.94L9.31 17ZM19 3H18V1H16V3H8V1H6V3H5C3.89 3 3.01 3.9 3.01 5L3 19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V8H19V19Z"
                          fill="white" />
                </svg>
                <span>{{ $t('navigation.furloughs') }}</span>
            </router-link>
            <router-link to="/mijn-dienst-rooster" v-if="$can('edit-my-service-roster')">
                <svg width="24"
                     height="24"
                     viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 12H12V17H17V12ZM16 1V3H8V1H6V3H5C3.89 3 3.01 3.9 3.01 5L3 19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3H18V1H16ZM19 19H5V8H19V19Z"
                          fill="white" />
                </svg>
                <span> {{ $t('navigation.my_service_schedule') }} </span>
            </router-link>
            <router-link to="/afdelingen" v-if="$can('add-and-edit-departments')">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_5040_625)">
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                              fill="white" />
                        <path d="M8 16C9.10457 16 10 15.1046 10 14C10 12.8954 9.10457 12 8 12C6.89543 12 6 12.8954 6 14C6 15.1046 6.89543 16 8 16Z" fill="white" />
                        <path d="M12 10C13.1046 10 14 9.10457 14 8C14 6.89543 13.1046 6 12 6C10.8954 6 10 6.89543 10 8C10 9.10457 10.8954 10 12 10Z" fill="white" />
                        <path d="M16 16C17.1046 16 18 15.1046 18 14C18 12.8954 17.1046 12 16 12C14.8954 12 14 12.8954 14 14C14 15.1046 14.8954 16 16 16Z" fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_5040_625">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                <span> {{ $t('navigation.departments') }} </span>
            </router-link>
        </div>
        <div class="profile">
            <div class="profile-wrapper">
                <img src="https://www.vlietoever.be/wp-content/uploads/2019/03/default-avatar-300x300.png">
                <span />

                <div class="arrows">
                    <span class="arrow-up" />
                    <span class="arrow-down" />
                </div>
            </div>
            <div class="drop-down-menu">
                <div id="nav_language_switch">
                    <span>{{ $t('navigation.language_switch.title') }}</span>
                    <a @click.prevent="changeLocale('nl')">{{ $t('navigation.language_switch.languages.nl') }}</a>
                    <a @click.prevent="changeLocale('en')">{{ $t('navigation.language_switch.languages.en') }}</a>
                    <a @click.prevent="changeLocale('de')">{{ $t('navigation.language_switch.languages.de') }}</a>
                </div>
                <div class="divider" />
                <a href="/logout">{{ $t('navigation.log_out') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
    import { i18n } from '../lang';

    export default {
        name: 'MainMenu',

        methods: {
            changeLocale(locale) {
                i18n.locale = locale;
                window.$cookies.set('application_language', locale, 36500);
            },
        },
    };
</script>
